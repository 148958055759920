import { SozlesmeModel } from './../models/sozlesme_model';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';


@Injectable({ providedIn: 'root' })
export class SozlesmeService {
  private readonly API_URL = `${environment.backendurl}api/sozlesme/`;

  constructor(private httpClient: HttpClient) { }

  getSozlesmeler(): Observable<SozlesmeModel[]> {

    return this.httpClient.get<SozlesmeModel[]>(this.API_URL);
  }

  getSozlesme(id: number): Observable<SozlesmeModel> {
    const url: string = `${this.API_URL}${id}/`;
    return this.httpClient.get<SozlesmeModel>(url);
  }
  getSozlesmelerForAddList(): Observable<SozlesmeModel> {
    return this.httpClient.get<SozlesmeModel>(this.API_URL + 'addlist/kayit/');
  }

  getSozlesmelerForSantiyeAddList(): Observable<SozlesmeModel[]> {
    return this.httpClient.get<SozlesmeModel[]>(this.API_URL + 'addlist/santiye/');
  }

  addSozlesmeStep1Post(step1data: any): Observable<SozlesmeModel> {
    return this.httpClient.post<SozlesmeModel>(this.API_URL, step1data);
  }
  addSozlesmeStep1Patch(step1data: any, sozlesme_id): Observable<SozlesmeModel> {
    return this.httpClient.patch<SozlesmeModel>(this.API_URL + sozlesme_id + '/', step1data);
  }
  addSozlesmeStep2(step2data: any, sozlesme_id): Observable<SozlesmeModel> {
    return this.httpClient.patch<SozlesmeModel>(this.API_URL + sozlesme_id + '/', step2data);
  }
  addSozlesmeStep3(step3data: any, sozlesme_id): Observable<SozlesmeModel> {
    return this.httpClient.patch<SozlesmeModel>(this.API_URL + sozlesme_id + '/', step3data);
  }
  addSozlesmeStep4(step4data: any, sozlesme_id): Observable<SozlesmeModel> {
    return this.httpClient.patch<SozlesmeModel>(this.API_URL + sozlesme_id + '/', step4data);
  }
  santiyesizSozlesme(): Observable<SozlesmeModel> {
    const url: string = `${this.API_URL}yarim/`;

    return this.httpClient.get<SozlesmeModel>(url);
  }
}
